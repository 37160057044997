import { AsyncAuthWrapper } from './frontend-auth/auth-wrapper';
import { parseSearchArgs } from './frontend-auth/handover-token';

import { Editor } from './components/Editor';

/**
 * The elements that make up the editor page.
 */
export interface UIElements {
    main: HTMLElement,

    /** The canvas for the redaction box editor */
    display: HTMLCanvasElement,

    /** The container div for the template editor */
    templatingDisplay: HTMLDivElement,

    /** The embed element to load PDFs in */
    pdfDisplay: HTMLEmbedElement,

    /** Document filters and list, in the left sidebar */
    documents: {
        div: HTMLDivElement;
        /** Button that says "Refresh", next to documents */
        refreshButton: HTMLButtonElement;

        /** The "Results" heading, above recentDocsContainer */
        resultsHeader: HTMLElement;
        /** The container for the document list */
        recentDocsContainer: HTMLDivElement;
    },

    /** Menu on the left of the page */
    smallMenu: {
        div: HTMLDivElement;
        closeMenuButton: HTMLButtonElement;
        /** Button to open the sidebar. Not really in the smallMenu, but it goes where the menu was when it's hidden. */
        openMenuButton: HTMLButtonElement;
        /** Button to create a new redaction box */
        newRedactionButton: HTMLButtonElement;
        /** Button for "show redacted file only" */
        redactedButton: HTMLButtonElement;
        /** Button for "show unredacted file" */
        unredactedButton: HTMLButtonElement;
    }

    /** Toolbar in the bottom right, primarily containing the submit button */
    toolbar: {
        div: HTMLDivElement,
        /** Button that says "Submit" */
        submitButton: HTMLButtonElement,
        /** Button that says "New Candidates" */
        newCandidatesButton: HTMLButtonElement,
    },
}

const ui: UIElements = {
    main: document.querySelector("main")!,

    display: document.getElementById("display") as HTMLCanvasElement,
    templatingDisplay: document.getElementById("templating-display") as HTMLDivElement,
    pdfDisplay: document.getElementById("embed-pdf") as HTMLEmbedElement,

    documents: {
        div: document.getElementById("recent-docs") as HTMLDivElement,
        refreshButton: document.getElementById("refresh-button") as HTMLButtonElement,
        resultsHeader: document.getElementById("results-header") as HTMLDivElement,
        recentDocsContainer: document.getElementById("recent-docs-container") as HTMLDivElement,
    },

    smallMenu: {
        div: document.getElementById("small-menu") as HTMLDivElement,
        closeMenuButton: document.getElementById("close-menu-button") as HTMLButtonElement,
        openMenuButton: document.getElementById("open-menu-button") as HTMLButtonElement,
        newRedactionButton: document.getElementById("new-redaction") as HTMLButtonElement,
        unredactedButton: document.getElementById("unredacted-button") as HTMLButtonElement,
        redactedButton: document.getElementById("redacted-button") as HTMLButtonElement,
    },

    toolbar: {
        div: document.getElementById("toolbar") as HTMLDivElement,
        submitButton: document.getElementById("submit-button") as HTMLButtonElement,
        newCandidatesButton: document.getElementById("new-candidates") as HTMLButtonElement,
    },
};

interface Filters {
    CandidateId: string;
    JobTitle: string;
    CandidateName: string;
    JobId: string;
}

const filters: Filters = {
    CandidateId: '',
    JobTitle: '',
    CandidateName: '',
    JobId: '',
};

function sleep(duration: number): Promise<void> {
    return new Promise(resolve => setTimeout(() => resolve(), duration));
}

async function hidePageQuick() {
    ui.main.classList.add('hidden');
}

async function showPage() {
    ui.main.classList.remove('hidden');
    ui.main.classList.remove('hidden-smooth');
}

/**
 * Hide the sidebar.
 *
 * @param hard - if true, the button to show the sidebar again is removed
 */
async function hideSidebar(hard: boolean = false) {
    ui.main.classList.add('hidden-smooth');
    ui.smallMenu.div.classList.add('hidden-smooth');
    await sleep(320);
    ui.main.style.paddingLeft = '50px';
    ui.main.style.paddingRight = '50px';
    ui.smallMenu.closeMenuButton.classList.add('hidden');
    ui.documents.div.classList.add('hidden');
    ui.main.classList.remove('hidden');
    ui.main.classList.remove('hidden-smooth');
    if (hard) ui.smallMenu.openMenuButton.classList.add('hidden');
    else ui.smallMenu.openMenuButton.classList.remove('hidden');
    ui.smallMenu.div.classList.remove('hidden-smooth');
}

async function openSidebar() {
    ui.main.classList.add('hidden-smooth');
    ui.smallMenu.div.classList.add('hidden-smooth');
    await sleep(320);
    ui.main.style.paddingLeft = 'unset';
    ui.main.style.paddingRight = 'unset';
    ui.smallMenu.openMenuButton.classList.add('hidden');
    ui.documents.div.classList.remove('hidden');
    ui.main.classList.remove('hidden');
    ui.main.classList.remove('hidden-smooth');
    ui.smallMenu.closeMenuButton.classList.remove('hidden');
    ui.smallMenu.div.classList.remove('hidden-smooth');
}

ui.smallMenu.closeMenuButton.addEventListener('click', () => hideSidebar());
ui.smallMenu.openMenuButton.addEventListener('click', openSidebar);

function closeOverlays() {
    document.getElementById('overlay')?.classList.remove('active');
    document.getElementById('success-popup')?.classList.remove('active');
}

document.querySelectorAll('.close-overlay')
    .forEach(elem => elem.addEventListener('click', closeOverlays));

export function activateOverlay() {
    document.getElementById('overlay')?.classList.add('active');
}
export function showSuccess() {
    activateOverlay();
    document.getElementById('success-popup')?.classList.add('active');
}

let editor: Editor;

function filterInputUpdate(ev: Event) {
    const target = ev.target as HTMLInputElement;
    const filter = target.getAttribute('x-filter');
    // @ts-ignore
    filters[filter!] = target.value;
    editor.updateList(filters);
}

const filterInputs = document.querySelectorAll('#filters > input');
filterInputs.forEach(elem => {
    const input = elem as HTMLInputElement;
    input.addEventListener('change', filterInputUpdate);
    input.addEventListener('keypress', filterInputUpdate);
    input.addEventListener('keyup', filterInputUpdate);
});

/**
 * Convert a string to upper cammel case. For example:
 *
 * alreadyUpperCamel -> AlreadyUpperCamel
 * lowerCammelCase -> LowerCammelCase
 * snake_case -> SnakeCase
 * With some spaces -> WithSomeSpaces
 * Part of LGBTQ -> PartOfLGBTQ
 */
function toUpperCammelCase(s: string): string {
    // First, remove surrounding whitespace
    s = s.trim();
    // The output starts with the first character, lower cased
    let output = s[0].toUpperCase();
    // Then we iterate over the remaining characters
    let isFollowingSpace = false;
    for (const ch of s.substring(1)) {
        switch (ch) {
            case '_':
            case '-':
            case ' ':
            case '\t':
            case '\r':
            case '\n':
                isFollowingSpace = true;
                break;
            default:
                if (isFollowingSpace) {
                    output += ch.toUpperCase();
                    isFollowingSpace = false;
                } else
                    output += ch;
        }
    }
    return output;
}

function testToUpperCammelCase() {
    // alreadyUpperCamel -> alreadyUpperCamel
    console.assert(toUpperCammelCase("AlreadyUpperCamel") === "AlreadyUpperCamel");
    // UpperCammelCase -> upperCammelCase
    console.assert(toUpperCammelCase("lowerCammelCase") === "LowerCammelCase");
    // snake_case -> snakeCase
    console.assert(toUpperCammelCase("snake_case") === "SnakeCase");
    // With some spaces -> withSomeSpaces
    console.assert(toUpperCammelCase("With some spaces") === "WithSomeSpaces");
    // Part of LGBTQ -> partOfLGBTQ
    console.assert(toUpperCammelCase("Part of LGBTQ") === "PartOfLGBTQ");
}

const args = parseSearchArgs();

if (args.sat) {
    // Single-access token flow
    console.log("SAT Active");

    // First, we remove the token from the URL
    history.replaceState(null, "", "?");

    // We then parse the token, which has the form:
    // <store-token-id>.<token:jwt>
    const tokenParts = args.sat.split(".");
    const storeTokenId = tokenParts[0];
    const token = tokenParts.slice(1).join(".");
    // We then start the fetch for the store token from simple-store. This is what makes the token
    // single-use. The store will only give us the token from the ID once.
    // Note that we don't await it here!
    const storeTokenPromise = fetch("https://simple-store.mevitae.net/api/get", {
        method: "POST",
        headers: {
            "X-MeVitae-Key": "public-editor-us",
        },
        body: storeTokenId,
        mode: "cors",
        cache: "no-store",
        redirect: "error",
    }).then(resp => {
        if (resp.status === 404) alert("Expired token, please use a new link.");
        if (!resp.ok) throw new Error("simple-store response not ok");
        return resp.text();
    }).then(token => {
        const successPopup = document.getElementById('success-popup');
        if (successPopup) {
            const {
                success_message: successMessage,
                success_button_text: successButtonText,
                success_button_action: successButtonAction,
            } = JSON.parse(atob(token.split(".")[1].replaceAll("-", "+").replaceAll("_", "/")));
            if (successMessage) {
                const messagePara = successPopup.querySelector("#success-message");
                if (messagePara)
                    (messagePara as HTMLParagraphElement).innerText = successMessage;
                else console.error("success-message element does not exist");
            }
            const button = successPopup.querySelector("button.close-overlay");
            if (button) {
                if (successButtonText)
                    (button as HTMLButtonElement).innerText = successButtonText;
                if (successButtonAction) {
                    button.removeEventListener("click", closeOverlays);
                    button.addEventListener("click", () => location.href = successButtonAction);
                }
            } else console.error("success-popup > button does not exist");
        } else console.error("success-popup element does not exist");
        return token;
    });

    /**
     * Make a fetch request, with SAT credentials.
     */
    async function satFetch(
        url: string,
        options: RequestInit = {},
        retries = 1,
        timeout = 90000,
        refresh = true,
    ): Promise<Response> {
        const storeToken = await storeTokenPromise;
        if (!options) options = {};

        const controller = new AbortController();
        const timeoutId = setTimeout(() => controller.abort(), timeout);
        options.signal = controller.signal;

        if (!options.headers) options.headers = {};
        // @ts-ignore
        options.headers['X-MeVitae-Store-Token'] = storeToken;
        // @ts-ignore
        options.headers['X-MeVitae-Single-Access-Token'] = token;

        const resp = await fetch(url, options);
        clearTimeout(timeoutId);
        if (resp.status === 401 || resp.status === 403) {
            alert("unauthorized");
            window.location.href = "https://accounts.mevitae.com";
        }
        else if (!resp.ok && retries > 0) return satFetch(url, options, retries - 1, timeout, refresh);
        return resp;
    }

    editor = new Editor(satFetch, ui, false);

    hideSidebar(true);
    async function load() {
        await editor.singleAccess();
        // If selected, give is 500ms to load before fading in (and time for the hidden buttons to fade out)
        await sleep(500)
        ui.smallMenu.div.classList.remove('hidden');
        showPage();
    }
    load();
} else {
    console.log("No SAT:", args);
    // Usual flow, with SSO auth
    editor = new Editor(
        AsyncAuthWrapper.fromSession(() => location.href = "https://accounts.mevitae.com").fetch,
        ui,
        true,
    );

    // The query parameters are used to set the default filters, and optionally auto-select a
    // document
    for (const key in args) {
        const filter = toUpperCammelCase(key);
        for (const input of filterInputs) {
            if (input.getAttribute('x-filter') === filter) {
                const value = args[key];
                (input as HTMLInputElement).value = value;
                // @ts-ignore
                filters[filter] = value;
                break;
            }
        }
    }
    editor.updateList(filters);
    if (args.job_id && args.candidate_id && args.doc_type && !isNaN(Number(args.doc_type))) {
        ui.smallMenu.div.classList.add('hidden-smooth');
        ui.smallMenu.div.classList.add('hidden');
        editor.autoselect(args.job_id, args.candidate_id, Number(args.doc_type), args.job || "a")
            .then(async selected => {
                // If selected, give is 500ms to load before fading in (and time for the hidden buttons to fade out)
                if (selected) await sleep(500)
                ui.smallMenu.div.classList.remove('hidden');
                if (selected) hideSidebar();
                else openSidebar();
            });
    } else {
        showPage();
    }
}
