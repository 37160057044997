import { ParsedHandoverTokenWithUserInfo, clearSessionHandoverToken, getSessionHandoverToken, refreshHandoverToken } from './handover-token';

export type AuthFetch = (url: string, options?: RequestInit) => Promise<Response>;

export class LazyAuthWrapper {
    authWrapper: AsyncAuthWrapper | null;
    generate: () => AsyncAuthWrapper;

    constructor(generate: () => AsyncAuthWrapper) {
        this.authWrapper = null;
        this.generate = generate;

        this.fetch = this.fetch.bind(this);
        this.token = this.token.bind(this);
        this.logout = this.logout.bind(this);
    }

    static fromSession(navigateToLogin: () => void): LazyAuthWrapper {
        return new LazyAuthWrapper(() => AsyncAuthWrapper.fromSession(navigateToLogin));
    }

    asyncAuthWrapper(): AsyncAuthWrapper {
        if (!this.authWrapper) this.authWrapper = this.generate();
        return this.authWrapper;
    }

    fetch(url: string, options: RequestInit = {}, retries = 1, timeout = 90000): Promise<Response> {
        return this.asyncAuthWrapper().fetch(url, options, retries, timeout);
    }

    token(): Promise<ParsedHandoverTokenWithUserInfo> {
        return this.asyncAuthWrapper().token();
    }

    logout(): Promise<void> {
        return this.asyncAuthWrapper().logout();
    }
}

export class LazyMaybeAuthWrapper {
    #authWrapper: Promise<AuthWrapper | null> | null;
    navigateToLogin: () => void;

    constructor(navigateToLogin: () => void) {
        this.#authWrapper = null;
        this.navigateToLogin = navigateToLogin;
    }

    static fromSession(navigateToLogin: () => void): LazyMaybeAuthWrapper {
        return new LazyMaybeAuthWrapper(navigateToLogin);
    }

    authWrapper(): Promise<AuthWrapper | null> {
        if (!this.#authWrapper) {
            this.#authWrapper = AuthWrapper.tryFromSession(this.navigateToLogin);
        }
        return this.#authWrapper;
    }

    async mustAuthWrapper(): Promise<AuthWrapper> {
        const authWrapper = await this.authWrapper();
        if (authWrapper) return authWrapper;
        this.navigateToLogin();
        throw new Error("navigateToLogin should not return!");
    }

    mustAsyncAuthWrapper(): AsyncAuthWrapper {
        return new AsyncAuthWrapper(this.mustAuthWrapper());
    }
}

export class AsyncAuthWrapper {
    authWrapperPromise: Promise<AuthWrapper>;

    constructor(authFetchPromise: Promise<AuthWrapper>) {
        this.authWrapperPromise = authFetchPromise;

        this.fetch = this.fetch.bind(this);
        this.token = this.token.bind(this);
        this.logout = this.logout.bind(this);
    }

    static fromSession(navigateToLogin: () => void): AsyncAuthWrapper {
        return new AsyncAuthWrapper(AuthWrapper.fromSession(navigateToLogin));
    }

    async fetch(url: string, options: RequestInit = {}, retries = 1, timeout = 90000): Promise<Response> {
        const wrapper = await this.authWrapperPromise;
        return wrapper.fetch(url, options, retries, timeout);
    }

    async token(): Promise<ParsedHandoverTokenWithUserInfo> {
        const wrapper = await this.authWrapperPromise;
        return wrapper.token;
    }

    async logout(): Promise<void> {
        const wrapper = await this.authWrapperPromise;
        return wrapper.logout();
    }
}

export class AuthWrapper {
    token: ParsedHandoverTokenWithUserInfo;
    navigateToLogin: () => void;
    /**
     * If there's currently a refresh occurring, a promise resolving when it's complete.
     *
     * This is used to prevent multiple simultaneous refreshes, one of which must fail, since a
     * refresh token only works once.
     */
    #refresh: Promise<void> | null;

    constructor(token: ParsedHandoverTokenWithUserInfo, navigateToLogin: () => void) {
        this.token = token;
        this.navigateToLogin = navigateToLogin;
        this.#refresh = null;

        this.fetch = this.fetch.bind(this);
    }

    logout() {
        clearSessionHandoverToken();
        this.navigateToLogin();
    }

    static async tryFromSession(navigateToLogin: () => void): Promise<AuthWrapper | null> {
        // First, try to load a new token from the search query
        const { handoverToken } = await getSessionHandoverToken();
        if (handoverToken) {
            return new AuthWrapper(handoverToken, navigateToLogin);
        }
        return null;
    }

    static async fromSession(navigateToLogin: () => void): Promise<AuthWrapper> {
        const wrapper = await AuthWrapper.tryFromSession(navigateToLogin);
        if (wrapper) return wrapper;
        // If there's no auth, navigate to the login page!
        navigateToLogin();
        throw new Error("navigateToLogin should not return!");
    }

    /**
     * Refresh the token, and resolve when refreshed.
     */
    refreshToken(): Promise<void> {
        // If there's already a refresh in progress, there's no need to start another one.
        // In fact, starting another one would cause one refresh to fail, since the refresh token
        // can only be used once.
        if (!this.#refresh) {
            // Except if there's not a refresh token, we can't actually do a refresh...
            if (!this.token.refreshToken) return Promise.resolve();
            this.#refresh = refreshHandoverToken(this.token.userToken, this.token.serviceToken, this.token.refreshToken, true, true)
                .then(token => {
                    if (!token) {
                        // Logout if the refresh failed.
                        this.logout();
                    } else {
                        // Otherwise, store the new token, and clear the current refresh.
                        // @ts-ignore
                        this.token = token;
                        this.#refresh = null;
                    }
                })
                .catch(err => {
                    console.log("Failed to refresh token:");
                    console.error(err);
                    this.navigateToLogin();
                });
        }
        return this.#refresh;
    }

    fetch(url: string, options: RequestInit = {}, retries = 1, timeout = 90000, refresh = true): Promise<Response> {
        if (!options) options = {};

        const controller = new AbortController();
        const timeoutId = setTimeout(() => controller.abort(), timeout);
        options.signal = controller.signal;

        // Set authorization tokens
        if (!options.headers) options.headers = {};
        // @ts-ignore
        options.headers['Authorization'] = 'Bearer ' + this.token.userToken;
        // @ts-ignore
        options.headers['X-MeVitae-Service-Token'] = this.token.serviceToken;

        return fetch(url, options).then(res => {
            clearTimeout(timeoutId);
            if (res.status === 401 || res.status === 403) {
                if (refresh && this.token.refreshToken) {
                    return this.refreshToken()
                        .then(() => this.fetch(url, options, retries - 1, timeout, false));
                }
                this.logout();
            }
            else if (!res.ok && retries > 0) return this.fetch(url, options, retries - 1, timeout, refresh);
            return res;
        });
    }
}
