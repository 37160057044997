import { Page } from './Page';

const DEFAULT_RESIZE_BOX_RADIUS = 13;
const DELETE_SIZE = 35;

export class Box {
    bounds: { top: number; bottom: number; left: number; right: number };
    color: string;
    binImage?: HTMLImageElement;

    constructor(
        bounds: { top: number; bottom: number; left: number; right: number },
        color: string
    ) {
        this.bounds = bounds;
        this.color = color;
        const img = new Image();
        img.onload = () => {
            this.binImage = img;
        };
        img.src = "trash-button.svg";
    }

    contains(offsetY: number, x: number, y: number, padding = 0): boolean {
        return (
            offsetY + this.bounds.top - padding <= y
            && y <= offsetY + this.bounds.bottom + padding
            && this.bounds.left - padding <= x
            && x <= this.bounds.right + padding
        );
    }

    draw(
        ctx: CanvasRenderingContext2D,
        yOffset: number,
        color: string | null = null,
        editor = false,
        selectedSide = -1
    ) {
        ctx.fillStyle = color || this.color;
        ctx.fillRect(
            this.bounds.left - 2,
            yOffset + this.bounds.top - 2,
            this.bounds.right - this.bounds.left + 4,
            this.bounds.bottom - this.bounds.top + 4
        );
        if (editor) this.drawEditor(ctx, yOffset, selectedSide);
    }

    deleteCoords(): { left: number; top: number } {
        return {
            left: (this.bounds.left + this.bounds.right) / 2 - DELETE_SIZE / 2,
            top: this.bounds.bottom + DEFAULT_RESIZE_BOX_RADIUS + DELETE_SIZE / 4,
        };
    }

    drawEditor(
        ctx: CanvasRenderingContext2D,
        yOffset: number,
        selectedSide: number,
        color = '#2A1A46',
        selectedColor = '#150A23',
    ) {
        const radius = DEFAULT_RESIZE_BOX_RADIUS;
        // Left adjust
        ctx.fillStyle = selectedSide === 0 ? selectedColor : color;
        ctx.fillRect(
            this.bounds.left - radius + 1,
            yOffset + (this.bounds.top + this.bounds.bottom) / 2 - radius / 2,
            radius,
            radius
        );
        // Right adjust
        ctx.fillStyle = selectedSide === 1 ? selectedColor : color;
        ctx.fillRect(
            this.bounds.right - 1,
            yOffset + (this.bounds.top + this.bounds.bottom) / 2 - radius / 2,
            radius,
            radius
        );
        // Top adjust
        ctx.fillStyle = selectedSide === 2 ? selectedColor : color;
        ctx.fillRect(
            (this.bounds.left + this.bounds.right) / 2 - radius / 2,
            yOffset + this.bounds.top - radius + 1,
            radius,
            radius
        );
        // Bottom adjust
        ctx.fillStyle = selectedSide === 3 ? selectedColor : color;
        ctx.fillRect(
            (this.bounds.left + this.bounds.right) / 2 - radius / 2,
            yOffset + this.bounds.bottom - 1,
            radius,
            radius
        );

        // Delete button
        ctx.fillStyle = '#d00';
        const coords = this.deleteCoords();
        ctx.fillRect(
            coords.left,
            yOffset + coords.top,
            DELETE_SIZE,
            DELETE_SIZE,
        );
        if (this.binImage) ctx.drawImage(
            this.binImage,
            coords.left + DELETE_SIZE / 8,
            yOffset + coords.top + DELETE_SIZE / 8,
            DELETE_SIZE * 0.75,
            DELETE_SIZE * 0.75,
        );
    }

    isDelete(yOffset: number, x: number, y: number): boolean {
        const coords = this.deleteCoords();
        return (
            coords.left <= x
            && x <= coords.left + DELETE_SIZE
            && yOffset + coords.top <= y
            && y <= yOffset + coords.top + DELETE_SIZE
        );
    }

    selectedSide(
        yOffset: number,
        x: number,
        y: number,
        radius: number = DEFAULT_RESIZE_BOX_RADIUS,
        largeRadius: number = DEFAULT_RESIZE_BOX_RADIUS * 2,
    ): number {
        function isInBox(boxX: number, boxY: number): boolean {
            return boxX <= x && x <= boxX + radius && boxY <= y && y <= boxY + radius;
        }

        // Left adjust
        if (isInBox(
            this.bounds.left - radius + 1,
            yOffset + (this.bounds.top + this.bounds.bottom) / 2 - radius / 2
        )) return 0;

        // Right adjust
        if (isInBox(
            this.bounds.right - 1,
            yOffset + (this.bounds.top + this.bounds.bottom) / 2 - radius / 2
        )) return 1;

        // Top adjust
        if (isInBox(
            (this.bounds.left + this.bounds.right) / 2 - radius / 2,
            yOffset + this.bounds.top - radius + 1
        )) return 2;

        // Bottom adjust
        if (isInBox(
            (this.bounds.left + this.bounds.right) / 2 - radius / 2,
            yOffset + this.bounds.bottom - 1
        )) return 3;

        // Try again with the larger radius if we have one
        if (largeRadius && largeRadius > 0) {
            return this.selectedSide(yOffset, x, y, largeRadius, 0);
        }
        // Otherwise we weren't within an adjust box
        return -1;
    }
}

export class SelectedBox {
    pageIdx: number;
    box: Box;
    selectedSide: number;
    dragging: boolean;
    selectOrigin: { x: number; y: number } | null;

    constructor(pageIdx: number, box: Box) {
        this.pageIdx = pageIdx;
        this.box = box;
        this.selectedSide = -1;
        this.dragging = false;
        this.selectOrigin = null;

        this.draw = this.draw.bind(this);
        this.mousedown = this.mousedown.bind(this);
        this.mousemove = this.mousemove.bind(this);
        this.mouseup = this.mouseup.bind(this);
    }

    draw(ctx: CanvasRenderingContext2D, pages: Page[]): void {
        this.box.draw(
            ctx,
            pages[this.pageIdx].top,
            '#8AC',
            true,
            this.selectedSide
        );
    }

    contains(pages: Page[], x: number, y: number): boolean {
        return this.box.contains(pages[this.pageIdx].top, x, y)
            || this.box.selectedSide(pages[this.pageIdx].top, x, y) !== -1;
    }

    isDelete(pages: Page[], x: number, y: number): boolean {
        return this.box.isDelete(pages[this.pageIdx].top, x, y);
    }

    mousedown(x: number, y: number, pages: Page[]): boolean {
        this.selectedSide = this.box.selectedSide(pages[this.pageIdx].top, x, y);
        this.selectOrigin = { x, y };
        if (this.selectedSide >= 0) return true;
        this.dragging = this.contains(pages, x, y);
        return this.dragging;
    }

    mousemove(x: number, y: number): void {
        if (this.dragging) {
            this.box.bounds.left += x - this.selectOrigin!.x;
            this.box.bounds.right += x - this.selectOrigin!.x;
            this.box.bounds.top += y - this.selectOrigin!.y;
            this.box.bounds.bottom += y - this.selectOrigin!.y;
        } else {
            switch (this.selectedSide) {
                // Left
                case 0:
                    this.box.bounds.left += x - this.selectOrigin!.x;
                    this.box.bounds.left = Math.min(this.box.bounds.left, this.box.bounds.right - 8);
                    break;
                // Right
                case 1:
                    this.box.bounds.right += x - this.selectOrigin!.x;
                    this.box.bounds.right = Math.max(this.box.bounds.right, this.box.bounds.left + 8);
                    break;
                // Top
                case 2:
                    this.box.bounds.top += y - this.selectOrigin!.y;
                    this.box.bounds.top = Math.min(this.box.bounds.top, this.box.bounds.bottom - 8);
                    break;
                // Bottom
                case 3:
                    this.box.bounds.bottom += y - this.selectOrigin!.y;
                    this.box.bounds.bottom = Math.max(this.box.bounds.bottom, this.box.bounds.top + 8);
                    break;
                default:
                    return;
            }
        }
        if (this.box.bounds.left < 0) this.box.bounds.left = 0;
        if (this.box.bounds.right < 8) this.box.bounds.right = 8;
        this.selectOrigin = { x, y };
    }

    mouseup(x: number, y: number) {
        this.mousemove(x, y);
        this.dragging = false;
        this.selectedSide = -1;
        this.selectOrigin = null;
    }
}
