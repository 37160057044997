import { Box } from './Box';

export class Page {
    scale: number;
    top: number;
    height: number;
    boxes: Box[];
    bitmap: ImageBitmap;

    constructor(scale: number, top: number, height: number, boxes: Box[], bitmap: ImageBitmap) {
        this.scale = scale;
        this.top = top;
        this.height = height;
        this.boxes = boxes;
        this.bitmap = bitmap;
    }

    draw(ctx: CanvasRenderingContext2D, boxes: boolean = true): void {
        ctx.drawImage(this.bitmap, 0, this.top);
        if (boxes) this.boxes.forEach(box => box.draw(ctx, this.top));
    }

    takeBox(x: number, y: number): Box | null {
        //if (y < this.top || this.top + this.height < y) return null;
        for (let idx = 0; idx < this.boxes.length; idx++) {
            if (this.boxes[idx].contains(this.top, x, y)) {
                return this.boxes.splice(idx, 1)[0];
            }
        }
        return null;
    }

    addBox(box: Box): void {
        this.boxes.push(box);
    }
}
