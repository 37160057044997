import * as fuzzysort from 'fuzzysort';

import { AuthFetch } from '../frontend-auth/auth-wrapper';
import { RedactionBoxEditor } from './RedactionBoxEditor';
import { TemplatedDocEditor } from './TemplatedDocEditor';
import { Doc } from '../template-render-html/component';
import { UIElements } from '../main';

interface Candidate {
    DomainId: string;
    IntegrationSettingsId: number;
    GroupId: string;
    JobId: string;
    JobTitle: string;
    CandidateId: string;
    FirstName: string;
    LastName: string;
    CandidateName: string;

    DocumentIds: string[];
    DocumentTypes: number[];
    DocumentJobs: string[];

    /**
     * If computed, the filter match score.
     */
    score?: number;
}

interface CandidateSnapshot {
    Candidates: Candidate[];
    Timestamp: string;
}

/**
 * Return a promise that resolves after `duration` milliseconds.
 */
function sleep(duration: number): Promise<void> {
    return new Promise(resolve => setTimeout(() => resolve(), duration));
}

/**
 * Returns true iff `xs` and `ys` have the same elements (in any order).
 */
function setsEqual(xs: string[], ys: string[]): boolean {
    for (const x of xs)
        if (!ys.includes(x)) return false;
    for (const y of ys)
        if (!xs.includes(y)) return false;
    return true;
}

/**
 * Returns true iff `a` and `b` have the same keys, with the same values.
 */
function recordsEqual(a: Record<string, string | number>, b: Record<string, string | number>): boolean {
    const aKeys = Object.keys(a);
    // The objects must have the same keys
    if (!setsEqual(aKeys, Object.keys(b))) return false;

    // And, if they do have the same keys, each value must be equal.
    for (const key of aKeys)
        if (a[key] !== b[key]) return false;

    return true;
}

export type Application = {
    Docs: Record<string, DocumentBoxData>,
};

export type DocumentBoxData = {
    HighlightDatas: HighlightData[],
    DocumentType: number,
};

export type HighlightData = {
    HighlightBoxes: HighlightBox[],
    Page: PageInfo,
};

export type PageInfo = {
    Width: number,
    Height: number,
    Scale: number,
    ImageBlobName: string,
};

export type HighlightBox = {
    Bounds: HighlightBoxBounds,
    IsImage: boolean,
    HexCode: string,
};

export type HighlightBoxBounds = {
    left: number,
    right: number,
    top: number,
    bottom: number,
};

function testRecordsEqual() {
    const a = { a: 1 };
    const b = { b: 2 };
    const b3 = { b: 3 };
    const ab3 = { a: 1, b: 3 };
    const ab32 = { a: 1, b: 3 };
    const ab5 = { a: 1, b: 5 };
    const ab2 = { a: 2, b: 3 };
    const anotherA = { a: 1 };
    const anotherB3 = { b: 3 };
    console.assert(recordsEqual(a, anotherA));
    console.assert(!recordsEqual(a, b));
    console.assert(!recordsEqual(a, b3));
    console.assert(recordsEqual(anotherB3, b3));
    console.assert(!recordsEqual(b, b3));
    console.assert(recordsEqual(ab3, ab32));
    console.assert(!recordsEqual(ab3, a));
    console.assert(!recordsEqual(ab3, b3));
    console.assert(!recordsEqual(ab3, b));
    console.assert(!recordsEqual(ab3, ab2));
    console.assert(!recordsEqual(ab3, ab5));
}

export class Editor {
    private redactionBoxEditor: RedactionBoxEditor;
    private templatedDocEditor: TemplatedDocEditor;

    private ui: UIElements;

    private localList: Promise<CandidateSnapshot>;
    // private webSocket: WebSocket;
    private newCandidates: Candidate[];
    private filters: any;
    private currentJob?: string;
    private DocumentType = {
        "0": 'None',
        "1": 'CV',
        "2": 'Application Form',
        "3": 'Cover Letter',
        "4": 'Statement Of Work',
        "10": 'Other',
    };
    private authFetch: AuthFetch;

    /**
     * @param authFetch - The function to use to make authenticated API calls
     * @param doList - If false, the document list is not loaded. This should be used if you intend to
     * call `singleAccess`, and not display the document list.
     */
    constructor(
        authFetch: AuthFetch,
        ui: UIElements,
        doList: boolean,
    ) {
        this.authFetch = authFetch;
        this.ui = ui;

        this.redactionBoxEditor = new RedactionBoxEditor(authFetch, ui.display);
        this.templatedDocEditor = new TemplatedDocEditor(authFetch, ui.pdfDisplay, ui.templatingDisplay);

        //this.webSocket = new WebSocket(`ws://${location.host}/todo`);
        this.filters = ""
        this.newCandidates = []

        this.updateList = this.updateList.bind(this);
        this.localList = doList ? this.list() : Promise.reject("no list");
        //this.initWS()

        const { newRedactionButton, unredactedButton, redactedButton } = ui.smallMenu;

        newRedactionButton.addEventListener('click', this.redactionBoxEditor.newBox);
        window.addEventListener('keypress', ev => {
            if (ev.key === 'n' || ev.key === 'N') this.redactionBoxEditor.newBox();
            if (ev.key === 'v' || ev.key === 'V') {
                this.redactionBoxEditor.toggleUnredacted();
                this.redactionBoxEditor.draw();
                if (this.redactionBoxEditor.isShowingUnredacted()) {
                    unredactedButton.classList.add('hidden');
                    redactedButton.classList.remove('hidden');
                } else {
                    unredactedButton.classList.remove('hidden');
                    redactedButton.classList.add('hidden');
                }
            };
        });
        unredactedButton.addEventListener('click', () => {
            switch (this.currentJob) {
                case "f":
                    this.templatedDocEditor.embedPdf.style.display = "none";
                    break;
                default:
                    this.redactionBoxEditor.toggleUnredacted();
                    this.redactionBoxEditor.draw();
            }
            unredactedButton.classList.add('hidden');
            redactedButton.classList.remove('hidden');
        });
        redactedButton.addEventListener('click', () => {
            switch (this.currentJob) {
                case "f":
                    this.templatedDocEditor.embedPdf.style.display = "block";
                    break;
                default:
                    this.redactionBoxEditor.toggleUnredacted();
                    this.redactionBoxEditor.draw();
            }
            unredactedButton.classList.remove('hidden');
            redactedButton.classList.add('hidden');
        });
        ui.toolbar.submitButton.addEventListener('click', () => {
            if (!this.currentJob) return;
            switch (this.currentJob) {
                case "a":
                    this.redactionBoxEditor.submit();
                    break;
                case "f":
                    this.templatedDocEditor.save();
                    break;
                default:
                    throw new Error("invalid currentJob");
            }
        });
        ui.toolbar.newCandidatesButton.addEventListener("click", () => this.handleNewCandidatesClick());
        ui.documents.refreshButton.addEventListener("click", () => this.refreshList(this.filters));
    }

    public async handleNewCandidatesClick() {
        // @ts-ignore
        this.newCandidatesButton.classList.add('hidden');

        // Get the current list
        const list = await this.localList;
        // -- From now until the copying completes, there's no await, so no sync issues --
        const candidates = list.Candidates;
        console.log(candidates, " old")
        for (const candidate of this.newCandidates) {
            console.log(candidate)
            candidates.push(candidate)
        }
        this.newCandidates = [];
        console.log(candidates, " new")
        // -- Copying done, now we can do async again --
        this.updateList(this.filters);
    }

    // public initWS(){
    //   // When a WS connection is opened, send our current timestamp
    //   this.webSocket.onopen = async () => {
    //     this.webSocket.send(JSON.stringify({
    //       "MessageType": "timestamp",
    //       "data": (await this.localList).Timestamp,
    //    }));
    //   };

    //   // When we receive new candidates, store them and show the "New candidates" button
    //   this.webSocket.onmessage = (event) => {
    //     let data = JSON.parse(event.data);
    //     console.log("Data Received", data);
    //     this.newCandidates.push(data);
    //     this.dataReceived();
    //   };

    //   this.webSocket.onerror = (errorEvent) => {
    //     console.error('WebSocket error:', errorEvent);
    //     this.connectionClosed();
    //   };

    //   this.webSocket.onclose = (closeEvent) => {
    //     console.log('WebSocket connection closed:', closeEvent.code, closeEvent.reason);
    //     this.connectionClosed();
    //   };
    // }

    public connectionClosed() {
        this.ui.documents.refreshButton.classList.remove('hidden');
    }

    public dataReceived() {
        if (this.newCandidates.length) {
            this.ui.toolbar.newCandidatesButton.classList.remove('hidden');
        }
    }

    public async updateList(filters: any): Promise<void> {
        // Create our own filters object, that's just for this call.
        filters = structuredClone(filters);
        // If it's equal to the existing filters, don't do anything.
        if (recordsEqual(filters, this.filters)) return;
        // Otherwise, set the current filter object to ours.
        this.filters = filters;
        // We then wait for the local list, and for a 150ms timeout.
        const [list, _] = await Promise.all([this.localList, sleep(150)]);
        // After this duration, if the filters object isn't ours, we don't do anything.
        // This prevents a list update for every keypress...
        // Now it's only 150ms after the latest keypress!
        if (this.filters !== filters) return;

        const { recentDocsContainer } = this.ui.documents;
        if (recentDocsContainer && list.Candidates) {
            const listElement = this.createListElement(list.Candidates, filters);
            // Replace all the old children with our single new list
            for (const child of recentDocsContainer.children) {
                recentDocsContainer.removeChild(child);
            }
            recentDocsContainer.appendChild(listElement);
        }
    }

    public refreshList(filters: any): Promise<void> {
        this.localList = this.refresh();
        return this.updateList(filters);
    }

    private async list(): Promise<CandidateSnapshot> {
        const response = await this.authFetch('/list');
        if (!response.ok) {
            throw new Error('Non-200 status');
        }
        return await response.json();
    }

    private async refresh(): Promise<CandidateSnapshot> {
        const response = await this.authFetch('/refresh');
        if (!response.ok) {
            throw new Error('Non-200 status');
        }
        return await response.json();
    }

    private createListElement(candidates: Candidate[], filters: Record<string, string>): HTMLUListElement {
        const ul = document.createElement('ul');

        console.log(candidates);
        console.log("Scoring...");
        candidates.forEach(candidate => {
            candidate.CandidateName = candidate.FirstName + ' ' + candidate.LastName;
            candidate.score = Object.entries(filters)
                .filter(([_, value]) => value)
                // @ts-ignore
                .map(([field, value]) => fuzzysort.single(value, candidate[field]))
                .map(score => score === null ? -1000 : score.score)
                .reduce((a, b) => a + b, 0);
        });
        console.log("Scored!");

        let results: Candidate[] = [...candidates];
        results.sort((a, b) => b.score! - a.score!);

        if (results.length === 0) {
            results = candidates;
        }

        let selected: HTMLElement | null = null;
        let selectedInner: HTMLElement | null = null;

        if (filters.CandidateId || filters.JobTitle || filters.CandidateName || filters.JobId) {
            this.ui.documents.resultsHeader.style.display = 'block';

            results.forEach(candidate => {
                const li = document.createElement('li');
                li.addEventListener('click', () => {
                    if (selected) selected.classList.remove('selected');
                    selected = li;
                    selected.classList.add('selected');
                });

                const nameElement = document.createElement('p');
                nameElement.innerText = candidate.FirstName + ' ' + candidate.LastName;
                const candidateId = document.createElement('h3');
                candidateId.innerText = candidate.CandidateId;
                const jobTitle = document.createElement('p');
                jobTitle.innerText = candidate.JobTitle;
                const jobId = document.createElement('h3');
                jobId.innerText = candidate.JobId;
                const documentList = document.createElement('ul');
                //li.append(nameElement, candidateId, jobTitle, jobId, documentList);
                jobId.style.paddingTop = "0.75em";
                li.append(candidateId, nameElement, jobId, jobTitle, documentList);
                ul.appendChild(li);

                for (const idx in candidate.DocumentTypes) {
                    for (const job of candidate.DocumentJobs[idx]) {
                        let jobName;
                        switch (job) {
                            case "a":
                                jobName = "Anonymised";
                                break;
                            case "f":
                                jobName = "Templated";
                                break;
                            default:
                                continue;
                        }
                        const li = document.createElement('li');
                        // @ts-ignore
                        let text: string = this.DocumentType[candidate.DocumentTypes[idx].toString()];
                        li.innerText = text + " - " + jobName;

                        const paperclip = document.createElement("img");
                        paperclip.src = "paperclip.svg";
                        li.insertBefore(paperclip, li.firstChild);

                        li.addEventListener('click', _ => {
                            if (selectedInner) selectedInner.classList.remove('selected');
                            selectedInner = li;
                            selectedInner.classList.add('selected');
                            this.selectCandidate(candidate, Number(idx), job);
                        });

                        documentList.appendChild(li);
                    }
                }
            });
        } else {
            this.ui.documents.resultsHeader.style.display = 'none';
        }

        return ul;
    }

    private async selectCandidate(candidate: Candidate, documentIdx: number, job: string, showButtons: boolean = true) {
        const { newRedactionButton, unredactedButton, redactedButton } = this.ui.smallMenu;
        this.currentJob = job;
        switch (job) {
            case "a":
                this.templatedDocEditor.setVisibility(false);
                this.redactionBoxEditor.setVisiblity(true);
                await this.redactionBoxEditor.loadDocument(candidate.GroupId, candidate.DocumentIds[documentIdx]);
                if (showButtons) {
                    if (this.redactionBoxEditor.isShowingUnredacted()) {
                        unredactedButton?.classList.add('hidden');
                        redactedButton?.classList.remove('hidden');
                    } else {
                        unredactedButton?.classList.remove('hidden');
                        redactedButton?.classList.add('hidden');
                    }
                    this.ui.toolbar.submitButton.classList.remove('hidden');
                    newRedactionButton?.classList.remove('hidden');
                }
                break;
            case "f":
                this.fetchAndEditTemplatedDoc(candidate.GroupId, candidate.DocumentIds[documentIdx]);
                if (showButtons) {
                    this.templatedDocEditor.embedPdf.style.display = "none";
                    unredactedButton?.classList.add('hidden');
                    redactedButton?.classList.remove('hidden');
                    this.ui.toolbar.submitButton.classList.remove('hidden');
                    newRedactionButton?.classList.add('hidden');
                }
                break;
            default:
                throw new Error("unknown job type");
        }
    }

    /**
     * Select the candidate if exactly one candidate matches the job+candidate ID pair, and the correct document type.
     *
     * Returns a boolean indicating if a candidate was selected.
     */
    public async autoselect(jobId: string, candidateId: string, documentType: number, job: string, showButtons: boolean = true): Promise<boolean> {
        const candidates = (await this.localList).Candidates
            .filter(candidate => candidate.JobId === jobId && candidate.CandidateId === candidateId);
        if (candidates.length === 1) {
            const candidate = candidates[0];
            const documentIdx = candidate.DocumentTypes
                .findIndex(docType => docType.toString() === documentType.toString());
            if (documentIdx >= 0) {
                this.selectCandidate(candidates[0], documentIdx, job, showButtons);
                return true;
            }
        }
        return false;
    }

    /**
     * Load a document from the single-access token.
     */
    public async singleAccess(
        docId?: string,
        showButtons: boolean = true,
    ) {
        const resp = await this.authFetch("/single-access", {
            method: "POST",
            body: "",
        });
        if (!resp.ok) {
            alert("document unavailable");
            throw new Error("single-access response not ok");
        }
        const candidate: Candidate = await resp.json();
        let documentIdx = 0;
        if (docId) {
            documentIdx = candidate.DocumentIds.indexOf(docId);
            if (documentIdx < 0) {
                alert("document not found");
                return;
            }
        }
        // TODO: Don't just select "a" (anonymisation), actually select the correct editor type.
        this.selectCandidate(candidate, 0, "a", showButtons);
    }

    async fetchAndEditTemplatedDoc(groupId: string, docId: string) {
        this.redactionBoxEditor.setVisiblity(false);
        await this.templatedDocEditor.fetchAndEdit(groupId, docId);
        this.templatedDocEditor.setVisibility(true);
    }

    editTemplatedDoc(doc: Doc) {
        this.templatedDocEditor.edit(doc);
        this.redactionBoxEditor.setVisiblity(false);
        this.templatedDocEditor.setVisibility(true);
    }
}
